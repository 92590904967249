<template>
    <!-- dialog弹框 -->
    <el-dialog
            :visible.sync="dialogVisible"
            title="消息"
            center
            width="520px"
            @open="openDialog"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
    >
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="labelTitle" name="0">
                <!--<div class="cascader-box">
                    <div>语音提醒：</div>
                    <el-cascader
                            ref="cascaderBox"
                            v-model="cascaderValue"
                            placeholder="请选择"
                            :options="options"
                            filterable
                            clearable
                            @change="changeCacader"
                    ></el-cascader>
                </div>-->

                <div class="item_hint">消息内容（300字以内）</div>
                <el-input
                        :disabled="cascaderValue && cascaderValue.length > 1"
                        type="textarea"
                        v-model.trim="messageInfo"
                        maxlength="300"
                        show-word-limit
                ></el-input>
                <!-- 底部按钮区域 -->
                <div class="dialog-footer">
                    <el-button size="small" @click="cancelBtn">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitForm"
                    >确定
                    </el-button
                    >
                </div>
            </el-tab-pane>
            <el-tab-pane label="查看发送消息" name="1">
                <div class="msgList" v-if="msgList.length>0" v-for="(item,idx) in msgList" :key="idx">
                    <div class="msg_time">{{item.time}}</div>
                    <div class="msg_info">{{item.message}}</div>
                </div>
                <div class="empty_msg" v-if="msgList.length==0">
                    <img :src="emptyImg"/>
                    <div>暂无消息</div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>
<script>
    import {updateTeacher} from "r/admin/staff";
    import localForage from "localforage";
    import emptyImg from "a/image/empty_img.png";

    export default {
        name: "msgDialog",
        props: {
            dialogVisibleFu: Boolean,
            allSend: [Number, String],
            userKey: [Number, String]
        },
        data() {
            return {
                emptyImg: emptyImg,
                dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
                all_send: this.allSend,// 发送消息 0-单个 1-全体 2-评委
                isDisable: false, // 提交按钮是否可点击（防重复点击）
                activeName: "0",
                messageInfo: null,// 需要发送的消息
                msgList: [], // 发送的消息列表
                options: [
                    {
                        value: 'a',
                        label: '上午第一场',
                        children: [
                            {
                                value: 1,
                                label: '开考前15分钟提醒',
                                message: '考生请注意，现在进行开考准备环节。请点击监控页面右上侧试卷袋确认按钮，扫描试卷袋上的二维码。扫描后可以拆封试卷袋，点击试卷确认按钮并扫描色彩试卷纸上二维码。完成上述操作请耐心等待，八点三十分可以查看试题。',
                            },
                            {
                                value: 2,
                                label: '开考提醒',
                                message: '考生请注意，现在可点击查看试题按钮，并开始作答',
                            },
                            {
                                value: 3,
                                label: '考试结束前15分钟提醒',
                                message: '距离色彩考试结束还有15分钟',
                            },
                            {
                                value: 4,
                                label: '考试结束提醒',
                                message: '考试时间已结束，请考生立即停笔，点击交卷登记按钮、扫描色彩试卷纸上二维码并按要求上传色彩科目作品图片。请先上传作品图片，确认无误再上传试卷袋封装图片，试卷袋一经封装将不得打开。确认图片上传后点击提交按钮，提交后将不能修改。',
                            },
                        ]
                    },
                    {
                        value: 'b',
                        label: '下午第一场',
                        children: [
                            {
                                value: 5,
                                label: '开考前15分钟提醒',
                                message: '考生请注意，现在进行开考准备环节。请点击监控页面右上侧试卷袋确认按钮，扫描试卷袋上的二维码。扫描后可以拆封试卷袋，点击试卷确认按钮并扫描速写试卷纸上二维码。完成上述操作请耐心等待，一点三十分可以查看试题。',
                            },
                            {
                                value: 6,
                                label: '开考提醒',
                                message: '考生请注意，现在可点击查看试题按钮，并开始作答',
                            },
                            {
                                value: 7,
                                label: '考试结束前15分钟提醒',
                                message: '距离速写考试结束还有15分钟',
                            },
                            {
                                value: 8,
                                label: '考试结束提醒',
                                message: '考试时间已结束，请考生立即停笔，点击交卷登记按钮、扫描速写试卷纸上二维码，将速写试卷纸放在监控画面能够覆盖的位置。然后点击试卷确认按钮并扫描素描试卷纸上二维码',
                            },
                        ]
                    },
                    {
                        value: 'c',
                        label: '下午第二场',
                        children: [
                            {
                                value: 9,
                                label: '开考提醒',
                                message: '考生请注意，现在可点击查看试题按钮，并开始作答',
                            },
                            {
                                value: 10,
                                label: '考试结束前15分钟提醒',
                                message: '距离素描考试结束还有15分钟',
                            },
                            {
                                value: 11,
                                label: '考试结束提醒',
                                message: '考试时间已结束，请考生立即停笔，点击交卷登记按钮、扫描素描试卷纸上二维码并按要求上传速写、素描两个科目作品图片。请先上传作品图片，确认无误再上传试卷袋封装图片，试题纸和上午的试卷袋应一起放入下午的回寄试卷袋中，试卷袋一经封装将不得打开。确认图片上传后点击提交按钮，提交后将不能修改。',
                            },
                        ]
                    },
                ],
                cascaderValue: [],
                messageType: null,// 语音类型
                labelTitle: '',

            };
        },
        methods: {
            // 语音-级联选择器改变
            changeCacader(val) {
                if (val && val.length > 1) {
                    let arr = this.$refs['cascaderBox'].getCheckedNodes();
                    this.messageInfo = arr[0]['data']['message'];
                    this.messageType = val[1];
                } else {
                    this.messageInfo = null;
                    this.messageType = null;
                }
            },
            // 打开dialog弹框触发
            openDialog() {
                if (this.all_send == 0) {
                    this.labelTitle = '发送单个消息';
                } else if (this.all_send == 1) {
                    this.labelTitle = '发送全体消息';
                } else if (this.all_send == 2) {
                    this.labelTitle = '发送评委消息';
                }
                this.msgList = [];
                let userKey = this.userKey;
                localForage.getItem(userKey).then((list) => {
                    if (list || list != null) {
                        this.msgList = list;
                    } else {
                        this.msgList = [];
                    }
                });
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn() {
                this.dialogVisible = false;
                this.$emit("closeDialog", this.dialogVisible, false);
                this.activeName = "0";
                this.msgList = [];
                if (this.messageType) {
                    this.$refs['cascaderBox'].$refs.panel.clearCheckedNodes();
                    this.cascaderValue = [];
                    this.messageType = null;
                    this.messageInfo = null;
                }
            },
            // 确定,提交表单
            async submitForm() {
                if (!(this.messageInfo)) {
                    return this.$message.error('请输入消息内容！');
                }
                this.isDisable = true;
                setTimeout(() => {
                    this.isDisable = false;
                }, 1000);
                this.dialogVisible = false;
                console.log(this.messageInfo)
                if (this.messageType) {
                    this.$emit("closeDialog", this.dialogVisible, true, this.messageInfo, this.messageType);
                    this.$refs['cascaderBox'].$refs.panel.clearCheckedNodes();
                    this.cascaderValue = [];
                    this.messageType = null;
                } else {
                    this.$emit("closeDialog", this.dialogVisible, true, this.messageInfo);
                }
                this.activeName = "0";
                this.messageInfo = null;
                this.msgList = [];

            },
            handleClick(tab, event) {
                // console.log(tab, event);
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            },
            allSend() {
                this.all_send = this.allSend;
            },
        },
    };
</script>
<style scoped lang="scss">
    @import "a/scss/dialog";

    ::v-deep .el-dialog__body {
        overflow: hidden !important;
        padding: 25px 0 30px;
    }

    ::v-deep .el-tabs {
        display: flex;
        flex-flow: column;
        height: 362px !important;
        overflow: hidden !important;

        .el-tabs__header {
            height: 36px;
            padding: 0 25px;

            .el-tabs__active-bar {
                background-color: #563279;
            }

            .el-tabs__item {
                height: 36px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                line-height: 36px;

                &.is-active {
                    color: #333333;
                }
            }
        }

        .el-tabs__content {
            flex: 1;
            padding: 0 25px;
            overflow-y: scroll !important;

            .cascader-box {
                display: flex;
                flex-flow: row;
                align-items: center;

                .el-cascader {
                    flex: 1;
                }
            }

            .item_hint {
                height: 28px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 28px;
                margin-top: 16px;
            }

            .el-textarea {
                height: 170px;

                .el-textarea__inner {
                    border-color: #E1E3E6;
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    height: 100%;
                    line-height: 24px;

                    &:focus {
                        border-color: #563279;
                    }
                }
            }

            .dialog-footer {
                padding: 24px 0 0;
                text-align: right;

                .el-button {
                    margin-left: 20px;
                }

                .el-button:nth-child(1):hover {
                    color: #56327a;
                    background-color: rgba(86, 50, 122, 0.2);
                    border-color: #56327a;
                }

                .el-button:nth-child(2) {
                    background-color: #56327a;
                    border-color: #56327a;
                }

                .el-button:nth-child(2):hover {
                    color: #ffffff;
                    background-color: #56327a;
                    border-color: #56327a;
                    opacity: 0.9;
                }

                .el-button--primary.is-disabled {
                    color: #fff;
                    background-color: #56327a;
                    border-color: #56327a;
                }
            }

            .msgList {
                padding: 7px 0;

                .msg_time {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 22px;
                }

                .msg_info {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }
            }

            .empty_msg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                > img {
                    width: 150px;
                }

                > div {
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }
</style>

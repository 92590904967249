const configApp = {
    rtc:{
        canCameraPublish:false
    },
    socket:{
        base_url : "https://socket-qhf.thussat.net/xechat",
        timeout : 2000,
        outgoing : 5000,
        incoming : 1
    },
    admin:{

    }
};
export default configApp

import { request } from "./network";

// 获取考试管理所有考试数据列表接口
export function getExamData(params) {
  return request({
    method: "get",
    url: "exam/lists",
    params,
  });
}

// 获取全部监考人员列表接口
export function getStaffAllData(params) {
  return request({
    method: "get",
    url: "/teacher/listAll",
    params,
  });
}

// 获取监考人员 分页带老师名称查询接口
export function getStaffPageData(params) {
  return request({
    method: "get",
    url: "/teacher/listPage",
    params,
  });
}

// 批量导入监考人员
export function importExcelTeacher(data) {
  return request({
    method: "post",
    url: "/teacher/importExcelTeacher?examId="+data.examId,
    data: data.file,
  });
}

// 根据考试examId查找监考老师的接口
export function getExamIdStaffData(params) {
  return request({
    method: "get",
    url: "/teacher/listAll",
    params,
  });
}

// 单个添加监考人员
export function addTeacher(data) {
  return request({
    method: "post",
    url: "/teacher/create?examId="+data.examId,
    data,
  });
}

// 修改监考人员
export function updateTeacher(examId, data) {
  return request({
    method: "post",
    url: `/teacher/update?examId=${examId}`,
    data,
  });
}
// 判断监考人员是否存在
export function getTeacher(data) {
  return request({
    method: "post",
    url: "/teacher/teacher/get",
    data,
  });
}

// 判断监考人员是否关联监控组
export function teacherIsGroup(params) {
  return request({
      method: "get",
      url: "/group/teacher/isGroup",
      params,
  });
}

// 单个删除监考人员
export function deleteTeacher(id, params) {
  return request({
    method: "delete",
    url: "/teacher/delete/"+id,
    params,
  });
}

// 批量删除监考人员
export function deleteTeacherBatch(params) {
  return request({
    method: "delete",
    url: "/teacher/delete/batch",
    params,
  });
}
